export async function getMyCurrentLocation() {
  if (!("geolocation" in navigator)) {
    this.errorStr = "Geolocation is not available.";
    return false;
  }

  // get position
  try {
    let res = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });

    return {
      lat: res.coords.latitude,
      lng: res.coords.longitude,
    };
  } catch (error) {
    console.log(error);
    return false;
  }
}
