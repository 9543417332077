import { createStore } from "vuex";

import location from "./location.js";

const store = createStore({
  state() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      cart_count: JSON.parse(localStorage.getItem("cartCount")),
      cancelTokens: [],
    };
  },
  getters: {
    cancelTokens(state) {
      return state.cancelTokens;
    },
  },
  mutations: {
    updateUser(state, newUser) {
      localStorage.setItem("user", JSON.stringify(newUser));
      state.user = newUser;
    },
    cartCount(state, newCartCount) {
      localStorage.setItem("cartCount", JSON.stringify(newCartCount));
      state.cart_count = newCartCount;
    },
    ADD_CANCEL_TOKEN(state, token) {
      state.cancelTokens.push(token);
    },
    CLEAR_CANCEL_TOKENS(state) {
      state.cancelTokens = [];
    },
  },
  modules: {
    location,
  },
  actions: {
    CANCEL_PENDING_REQUESTS(context) {
      // Cancel all request where a token exists
      context.state.cancelTokens.forEach((request) => {
        if (request.cancel) {
          request.cancel();
        }
      });

      // Reset the cancelTokens store
      context.commit("CLEAR_CANCEL_TOKENS");
    },
  },
});

export default store;
