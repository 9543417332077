import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import router from "./router";
import store from "./store/index";

const toaster = createToaster({
  position: "top-right",
  dismissible: true,
  duration: 3000,
});

const $axios = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
  timeout: 6000000,
});

$axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status == 422) {
        var errors = error.response.data.errors
          ? error.response.data.errors
          : error.response.data;
        for (var key in errors) {
          var validation_error = errors[key];
          for (var message in validation_error) {
            toaster.error(validation_error[message]);
          }
        }
      } else if (error.response.status == 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        toaster.error(
          error.response.data.message +
            "<br>Login terlebih dahulu untuk melanjutkan"
        );

        delete $axios.defaults.headers.Authorization;
        router.push("/signin");
      } else if (error.response.status == 403) {
        toaster.error(
          error.response.data.message +
            "<br>Login terlebih dahulu untuk melanjutkan"
        );
      }
    }
  }
);

$axios.interceptors.request.use(
  function (config) {
    //  Generate cancel token source
    let source = axios.CancelToken.source();

    // Set cancel token on this request
    config.cancelToken = source.token;

    // Add to vuex to make cancellation available from anywhere
    store.commit("ADD_CANCEL_TOKEN", source);

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default $axios;
