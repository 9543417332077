import $axios from "@/api";

import { getMyCurrentLocation } from "@/helpers/location_halpers";

export default {
  state: () => ({
    coordinate: null,
    nearestWarehouse: null,
  }),
  getters: {
    getCoordinate: (state) => state.coordinate,
    getNearestWarehouse: (state) => state.nearestWarehouse,
  },
  mutations: {
    setCoordinate(state, payload) {
      state.coordinate = payload;
    },
    setNearestWarehouse(state, payload) {
      state.nearestWarehouse = payload.id;
    },
    clearCoordinate(state) {
      state.coordinate = null;
    },
    clearNearestWarehouse(state) {
      state.nearestWarehouse = null;
    },
  },
  actions: {
    async fetchCoordinate({ commit, dispatch, rootState }) {
      let coords = await getMyCurrentLocation();

      if (!coords) {
        try {
          if (!rootState.user) {
            return false;
          }

          const { data } = await $axios.get(`/customer_address`);
          if (!data.customer_addresses) return false;

          const address =
            data.customer_addresses.find((item) => item.is_primary == 1) ??
            data.customer_addresses[0];

          if (!address.latitude || !address.longitude) {
            return false;
          }

          coords = {
            lat: address.latitude,
            lng: address.longitude,
          };

          if (coords) {
            commit("setCoordinate", coords);
            dispatch("fetchNearestWarehouse");
          }

          return coords;
        } catch (error) {
          console.log(error);
          return false;
        }
      }

      commit("setCoordinate", coords);
      dispatch("fetchNearestWarehouse");
    },

    async fetchNearestWarehouse({ commit, state }) {
      let coordinate = state.coordinate;
      if (!coordinate) {
        commit("clearNearestWarehouse");
        return false;
      }

      try {
        const { data } = await $axios.post(`/warehouse/nearest`, coordinate);
        commit("setNearestWarehouse", data.warehouse);
        return data.warehouse;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
  },
};
