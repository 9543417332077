import { createApp } from "vue";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import Toaster from "@meforma/vue-toaster";
import Datepicker from "vue3-datepicker";

import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./assets/scss/custom.scss";
import "remixicon/fonts/remixicon.css";

import App from "./App.vue";
import $axios from "./api";
import router from "./router";
import store from "./store/index";
import VueMobileDetection from "vue-mobile-detection";

import Skeleton from "vue-loading-skeleton";

import BootstrapVue3 from "bootstrap-vue-3";
import "vue-loading-skeleton/dist/style.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";

const app = createApp(App)
  .use(router)
  .use(VueSweetalert2)
  .use(store)
  .use(Toaster, {
    position: "top-right",
    dismissible: true,
    pauseOnHover: true,
  })
  .use(VueMobileDetection)
  .use(Skeleton)
  .use(BootstrapVue3);

app.component("datepicker", Datepicker);

app.config.globalProperties.$axios = { ...$axios };

app.mount("#app");
