import axios from "@/api";

export default async (to, from, next) => {
  if (localStorage.getItem("token")) {
    next();
    return;
  }

  localStorage.removeItem("token");
  localStorage.removeItem("user");
  delete axios.defaults.headers.Authorization;
  next("/signin");
};
